import { parseContent } from 'client/data/cms/content';

function getChildEntry(content, id) {
  return content.hasChild(id) ? content.child(id) : null;
}

function getMetadataValue(entry, key) {
  return entry && entry.hasMetadata && entry.hasMetadata(key) ? entry.metadata(key).value() : null;
}

export function transformVideos(apiResponse) {
  const content = parseContent(apiResponse);
  const videoContent = getChildEntry(content, 'video-content');
  const videoContentArray = videoContent ? videoContent.children() : [];
  const videos = [];

  videoContentArray.forEach(video => {
    videos.push({
      videoId: getMetadataValue(video, 'videoId'),
      uploadDate: getMetadataValue(video, 'uploadDate'),
      videoTitle: getMetadataValue(video, 'videoTitle'),
      videoDescription: getMetadataValue(video, 'videoDescription'),
      videoTranscript: getMetadataValue(video, 'videoTranscript'),
      videoType: getMetadataValue(video, 'videoType'),
      thumbnailURL: getMetadataValue(video, 'thumbnailURL'),
      googleThumbnail: getMetadataValue(video, 'googleThumbnail'),
      type: getMetadataValue(video, 'type'),
    });
  });

  return videos.length && videos;
}
