import React from 'react';
import PropTypes from 'prop-types';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';

export function SeoEmbeddedYoutube({ creativeId, videoAspectRatio }) {
  return (
    !!creativeId &&
    !!videoAspectRatio && <AspectRatioContainer aspectRatio={videoAspectRatio} className="embedded-youtube-container" />
  );
}

SeoEmbeddedYoutube.propTypes = {
  creativeId: PropTypes.string,
  videoAspectRatio: PropTypes.string,
};

SeoEmbeddedYoutube.defaultProps = {
  creativeId: null,
  videoAspectRatio: '16:9',
};
