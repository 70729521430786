import { progressiveComponent } from 'site-modules/shared/components/progressive-component/progressive-component';
import { SeoEmbeddedYoutube } from './seo-embedded-youtube';

export const EmbeddedYoutubeAsync = progressiveComponent({
  SEOComponent: SeoEmbeddedYoutube,
  fullComponent: {
    loader: () => import(/* webpackChunkName: "embedded-youtube" */ './embedded-youtube'),
    name: 'EmbeddedYoutube',
  },
});
