import React, { Fragment } from 'react';
import { lazyLoad } from 'site-modules/shared/components/lazy-load/lazy-load';
import { isNode } from 'client/utils/environment';
import { RenderWhenViewable } from 'site-modules/shared/components/render-when-viewable/render-when-viewable';
import { ErrorBoundary } from 'site-modules/shared/components/error-boundary/error-boundary';

function DummyComponent() {
  return false;
}

/**
 * This function combines lazyLoad and RenderWhenViewable with an SEOComponent to be rendered on SSR
 *
 * Example Usage:
 *
 * const MyComponent = progressiveComponent({
 *   SEOComponent: MySEOPlaceholderComponent,
 *   fullComponent: {
 *     loader: () => import(/* webpackChunkName: "my-component" * / 'path/to/my-component'),
 *     name: 'MyComponent',
 *   },
 *   opts: { verticalOffset: '100%' },
 *   dataTrackingParent: 'my-widget-view-id',
 *   Renderer: MyRenderer,
 *   expectedComponentHeight: '500px',
 * });
 *
 * Then use MyComponent normally:
 * <MyComponent {...props} />
 *
 * SEOComponent is optional. When absent, function returns a simple lazyLoad on RenderWhenViewable
 *
 * Renderer: The renderer to use - defaults to RenderWhenViewable
 * opts: The options you would like to pass to the renderer
 *   - See 'site-modules/shared/components/render-when-viewable/render-when-viewable'.
 * dataTrackingParent: Temporary fix for widget_view
 * expectedComponentHeight: The expected height of the final enhanced component
 *   - may help CLS when enhanced components apply styles which alters their final height. (Ex. Bootstrap cols)
 *
 * @returns React Component
 */
export function progressiveComponent({
  SEOComponent = DummyComponent,
  fullComponent,
  dataTrackingParent,
  opts = { verticalOffset: '100%' },
  Renderer = RenderWhenViewable,
  expectedComponentHeight,
}) {
  class ProgressiveComponent extends React.Component {
    constructor(props) {
      super(props);

      this.EnhancedComponent =
        !isNode() &&
        lazyLoad({
          loader: fullComponent.loader,
          componentName: fullComponent.name,
          loading: () => <SEOComponent {...props} />,
        });

      this.state = {
        hasComponentHeight: !!expectedComponentHeight,
        isSSR: true,
      };
    }

    componentDidMount() {
      // Hydration failed fix
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        isSSR: false,
      });
    }

    completeRender = () => {
      this.setState({ hasComponentHeight: false });
    };

    renderSEOComponent = () => <SEOComponent {...this.props} />;

    renderInnerContent() {
      const { isSSR } = this.state;
      return (
        <Fragment>
          {!isSSR && this.EnhancedComponent ? (
            <ErrorBoundary message={this.renderSEOComponent}>
              <Renderer {...opts} onRender={this.completeRender} placeholder={<SEOComponent {...this.props} />}>
                <this.EnhancedComponent {...this.props} />
              </Renderer>
            </ErrorBoundary>
          ) : (
            <SEOComponent {...this.props} />
          )}
        </Fragment>
      );
    }

    render() {
      const applyDataTrackingParent = dataTrackingParent || this.props.dataTrackingParent;
      const inlineStyle = {
        height: expectedComponentHeight,
        overflow: 'visible',
      };

      return applyDataTrackingParent || expectedComponentHeight ? (
        <div
          {...(this.state.hasComponentHeight ? { style: inlineStyle } : {})}
          {...(applyDataTrackingParent ? { 'data-tracking-parent': applyDataTrackingParent } : {})}
        >
          {this.renderInnerContent()}
        </div>
      ) : (
        <Fragment>{this.renderInnerContent()}</Fragment>
      );
    }
  }

  return ProgressiveComponent;
}
